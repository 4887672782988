body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.who p{
  line-height: 23px;

}






.counter-one__list {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* align-items: stretch; */
    -ms-flex-wrap: wrap;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    /* align-content: stretch; */
}

.bg-dark {
  background-color: #fff !important;
}
.pagination li{
  padding: 5px 15px;
  background-color: #a020f0;
  color: #fff;
  margin: 10px;
}

.pagination .selected  {
  background-color: gray;
 
}

.pagination .selected  a {

  color: #fff !important;
}
.page-num {
  color: #fff;
}


@import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');
* {
   box-sizing: border-box; 
}
body{
   font-family: 'Muli',sans-serif;
   background-color: #f0f0f0;

}

h1{
   margin: 50px 0 30px;
   text-align: center;
}

.faq-container{
   max-width: 600px;
   margin: 0 auto;
}

.faq{
   background-color: transparent;
   border: 1px solid #9fa4a8;
   border-radius: 10px;
   margin: 20px 0;
   padding: 30px;
   position: relative;
   overflow: hidden;
   transition: 0.3s ease;
}

.faq.active {
   background-color: #fff;
   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 
   6px rgba(0, 0, 0, 0.1);
}

.faq.active::before,
.faq.active::after {
   content: '\f075';
   font-family: 'Font awesome 5 free';
   color: #2ecc71;
   font-size: 7rem;
   position: absolute;
   opacity: 0.2;
   top: 20px;
   left: 20px;
   z-index: 0;
}

.faq.active::before{
   color: #3498db;
   top: -10px;
   left: -30px;
   transform: rotateY(180deg);
}

.faq-title{
   margin: 0 35px 0 0;
}

.faq-text{
   display: none;
   margin: 30px 0 0;
}

.faq.active .faq-text{
   display: block;
}

.faq-toggle{
   background-color: transparent;
   border-radius: 50%;
   border: 0;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   padding: 0;
   position: absolute;
   top: 30px;
   right: 30px;
   width: 30px;
   height: 30px;
}

.faq-toggle:focus{
   outline: none;
}

.faq-toggle .fa-times {
   display: none;
}

.faq.active .faq-toggle .fa-times {
   display: block;
   color: white;
}

.faq.active .faq-toggle .fa-chevron-down{
   display: none;
}

.faq.active .faq-toggle {
   background-color: #9fa4a8;
}



.quality-work__tab-box .tab-buttons .tab-btn span {
   position: relative;
   display: inline-block;
   font-size: 20px;
   color: var(--pifoxen-black);
   background-color: var(--pifoxen-extra-two);
   padding: 33px 0px 33px;
   border-radius: var(--pifoxen-bdr-radius);
   cursor: pointer;
   font-family: var(--pifoxen-font-two);
   width: 100%;
   text-align: center;
   overflow: hidden;
   -webkit-transition: all 500ms ease;
   transition: all 500ms ease;
   z-index: 1;
   border: 1px solid gray;
}


.comment-form__input-box input[type="text"], .comment-form__input-box input[type="password"],.comment-form__input-box input[type="number"], .comment-form__input-box select, .comment-form__input-box input[type="search"], .comment-form__input-box input[type="email"] {
   height: 64px;
   width: 100%;
   border: 1px solid red;
   border: none;
   background-color: white ;
   padding-left: 30px;
   padding-right: 30px;
   border-radius: var(--pifoxen-bdr-radius);
   /* outline: none; */
   font-size: 14px;
   color: var(--pifoxen-gray);
   display: block;
}
.contact-page textarea{
   background-color: white ;
}






.sliderimg{
   width: 100%;
   height: 60vw;
   object-fit: cover;
   background-position:center center;
   
}


.fade-in-text  {
   font-size: 6vw !important;
   color: var(--pifoxen-white);
   line-height: 90px;
   font-weight: 900;

 
   width: 50%;
 
  
   -webkit-transition-delay: 1000ms;
   transition-delay: 1000ms;
   -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
   transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
   transition: transform 2000ms ease, opacity 2000ms ease;
} 


.yyy {

  
      height: 60vw;
      width: 100%;
      background: black;
      overflow: hidden;
     
      background: #C33764;  /* fallback colour. Make sure this is just one solid colour. */
      background: -webkit-linear-gradient(rgba(29, 38, 113, 0.2), rgba(195, 55, 100, 0.8));
      background: linear-gradient(rgba(29, 38, 113, 0.2), rgba(195, 55, 100, 0.8)) ; /* The least supported option. */
  }
  .fade-in-text {
   margin-top: 1%;
   animation: fadeIn 5s;
   -webkit-animation: fadeIn 5s;
   -moz-animation: fadeIn 5s;
   -o-animation: fadeIn 5s;
   -ms-animation: fadeIn 5s;
 }
 @keyframes fadeIn {
   0% { opacity: 0; }
   100% { opacity: 1; }
 }

 @-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
} 


 



@media (min-width:320px)  {  }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }


@media screen and (min-width: 1200px) and (max-width: 1228px) {
.main-menu-wrapper__logo{
margin-top: 0px !important;

}
}